import React from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import insightRoutes from "../../../app/insightRoutes";
import {useSelector} from "react-redux";

const LeaseNav = ({lease}) => {
    const navigate = useNavigate()
    const { state } = useLocation()

    const { currentUser } = useSelector((state) => state.user)

    function getBackLabel() {
        if (state && state.from) {
            if (state.from == "units") return "Units"
            if (state.from == "applicants") return "Applications"
            if (state.from == "screenings") return "Screenings"
        }

        return "Back"
    }

    return (
        <>
            <div className="horiz-nav">
                {false && <a onClick={() => navigate(-1)} className="hv-sidebtn hv-sidebtn-left"><i className="fal fa-chevron-left"></i> {getBackLabel()}</a>}

                <div></div>

                <ul className="horiz-nav-list">
                    <li className="hn-item active"><Link to={insightRoutes.leaseShow(lease.hash_id)}> Summary</Link></li>
                    <li className="hn-item"><Link to={insightRoutes.communicationCenter(currentUser, lease.property_id)}> Communication</Link></li>
                    <li className="hn-item"><Link to={insightRoutes.maintenanceRequestList()}>Maintenance</Link></li>
                    <li className="hn-item"><Link to={insightRoutes.residentLedger(lease.hash_id)}>Ledger</Link></li>
                    {false && <li className="hn-item btn-rd-activity">Activity<i className="fas fa-caret-down"></i>
                        <ul className="nav-dropdown">
                            <li>Menu Item</li>
                            <li>Longer Menu Item</li>
                            <li>A Much Longer Menu Item</li>
                            <li>Perhaps A Menu Item</li>
                            <li>Menu Item</li>
                        </ul>
                    </li>}
                </ul>

                <div></div>
            </div>
        </>

    )}

export default LeaseNav;


