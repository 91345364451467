import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {loadPropertyOwner, searchForPropertyOwners, savePropertyOwner} from "../../../slices/propertySlice";
import store from "../../../app/store";

import {Form, Formik} from "formik";
import {useSelector} from "react-redux";
import FormItem from "../../shared/FormItem";
import insightUtils from "../../../app/insightUtils";
import insightRoutes from "../../../app/insightRoutes";
import RadioButtonGroup from "../../shared/RadioButtonGroup";
import {loadVendor} from "../../../slices/companySlice";

const PropertyOwnerEditPage = ({}) => {

    let navigate = useNavigate()
    let location = useLocation()
    let params = useParams()

    const [baseErrorMessage, setBaseErrorMessage] = useState("")

    const { constants } = useSelector((state) => state.company)

    const [propertyOwner, setPropertyOwner] = useState(null)

    useEffect(async() => {
        let newPropertyOwner = null;
        if (parseInt(params.propertyOwnerId) > 0) {
            const results = await store.dispatch(loadPropertyOwner({propertyOwnerId: params.propertyOwnerId})).unwrap()
            newPropertyOwner = Object.assign({}, results.data.property_owner)
        }

        if (!newPropertyOwner) {
            newPropertyOwner = insightUtils.emptyPropertyOwner()
        }

        setPropertyOwner(newPropertyOwner)
    }, [])


    function closeView(newPropertyOwnerId) {
        if (location.state && location.state.return_url) {
            let newValues = Object.assign({}, location.state.values)

            // If we added a vendor, send it back to the calling form
            if (newPropertyOwnerId && location.state.field_to_update) {
                insightUtils.setValuesWithDotNotation(newValues, location.state.field_to_update, newPropertyOwnerId)
            }

            navigate(location.state.return_url, {state: {values: newValues}})
        }
        else {
            navigate(insightRoutes.propertyOwnerList())
        }
    }


    async function handleFormikSubmit(values, {setSubmitting, setErrors}) {
        setBaseErrorMessage("")

        const results = await store.dispatch(savePropertyOwner({propertyOwner: values})).unwrap()
        const response = results.data

        console.log(response)
        setSubmitting(false);

        if (response.success) {
            store.dispatch(searchForPropertyOwners({}))
            closeView(response.property_owner.id)
        }
        else if (response.errors) {
            setErrors(response.errors)

            if (response.errors.base) {
                setBaseErrorMessage(response.errors.base.join(", "))
            }

            insightUtils.scrollTo('errors')
        }

    }

    return (
        <>
            {propertyOwner && <div className="section">

                <h2>{propertyOwner.id ? "Edit " + propertyOwner.name : "Add Property Owner"}</h2>
                <p className="text-center">Use this form to {propertyOwner.id ? "edit" : "create"} a property owner.</p>

                {baseErrorMessage && <div className="text-error">{baseErrorMessage}</div>}

                <Formik
                    initialValues={propertyOwner}
                    onSubmit={handleFormikSubmit}
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <div className="add-property-wrap">
                                <div className="form-row form-center">
                                    <FormItem label="Owner Type" name="owner_type">
                                        <RadioButtonGroup name="owner_type" options={constants.property_owner_type_options} direction="row" />
                                    </FormItem>
                                </div>
                                <div className="form-row form-center">
                                    <FormItem label="Name" name="name" />
                                </div>

                                <div className="form-nav">
                                    <a onClick={() => closeView()} className="btn btn-gray" disabled={isSubmitting}>
                                        <span>Cancel</span>
                                    </a>
                                    <button className="btn btn-red" type="submit" disabled={isSubmitting}>
                                        <span>{!isSubmitting ? "Save" : "Saving..."}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>}
        </>
    )}

export default PropertyOwnerEditPage;

