import React, {useState} from 'react';
import {useParams} from 'react-router-dom'

import {Form, Formik} from "formik";
import {useSelector} from "react-redux";
import FormItem from "../../shared/FormItem";
import insightUtils from "../../../app/insightUtils";
import {saveCompany} from "../../../slices/companySlice";
import store from "../../../app/store";
import StateDropdown from "../../shared/StateDropdown";

const CompanyEditForm = ({mode, closeModal}) => {
    const [baseErrorMessage, setBaseErrorMessage] = useState("")

    const { currentCompany } = useSelector((state) => state.company)

    let params = useParams();

    return (
        <>
            {mode == "screening-activation" && <>
                <h2>Activate Screening</h2>
                <p>Use this form to activate screening. First, confirm your company's information is correct, then click Continue.</p>
            </>}

            {mode != "screening-activation" && <>
                <h2>{"Edit " + currentCompany.name}</h2>
                <p>Use this form to update your company information.</p>
            </>}



            {baseErrorMessage && <div className="text-error">{baseErrorMessage}</div>}

            <Formik
                initialValues={currentCompany}
                onSubmit={(values, { setSubmitting, setErrors }) => {
                    setBaseErrorMessage("")

                    let companyValues = Object.assign({}, values)

                    companyValues.company_action = mode

                    store.dispatch(saveCompany({company: companyValues}))
                        .then((action) => {
                                const response = action.payload.data
                                console.log(response)

                                setSubmitting(false);

                                if (response.success) {
                                    closeModal()
                                }
                                else if (response.errors) {
                                    setErrors(response.errors)

                                    if (response.errors.base) {
                                        setBaseErrorMessage(response.errors.base)
                                    }

                                    insightUtils.scrollTo('errors')
                                }

                            },
                            () => {
                                // Error!
                                setBaseErrorMessage("Unable to activate screening")
                                setSubmitting(false);
                            })
                }}
            >
                {({ isSubmitting, values }) => (
                    <Form>
                        <div className="add-property-wrap">
                            <div className="form-row">
                                <FormItem label="Company Name" name="name" />
                                <FormItem label="Cell Phone" name="cell_phone" mask={insightUtils.phoneNumberMask()} />
                            </div>

                            <div className="form-row">
                                <FormItem label="Address" name="street" />
                                <FormItem label="City" name="city" />
                                <FormItem label="State" name={`state`}>
                                    <StateDropdown name={`state`}/>
                                </FormItem>
                                <FormItem label="Zip" name="zip" mask={insightUtils.zipMask()} />
                            </div>

                            <div className="form-nav">
                                <a onClick={() => closeModal()} className="btn btn-gray" disabled={isSubmitting}>
                                    <span>Cancel</span>
                                </a>
                                <button className="btn btn-red" type="submit" disabled={isSubmitting}>
                                    <span>{isSubmitting ? "Submitting..." : (mode == "screening-activation" ? "Continue" : "Save")}</span>
                                </button>
                            </div>

                            {false &&
                                <pre>
                                    {JSON.stringify(values)}
                                </pre>
                            }
                        </div>
                    </Form>
                )}
            </Formik>

        </>
    )}

export default CompanyEditForm;

