import React, {useState} from 'react';
import {useSelector} from "react-redux";
import LeaseDocsOnboardingLandingView from "./LeaseDocsOnboardingLandingView";

const LeaseDocsOnboardingPage = ({}) => {

    const { currentCompany, constants } = useSelector((state) => state.company)

    return (
        <>
            {currentCompany &&
                <>
                    <LeaseDocsOnboardingLandingView />
                </>
            }
        </>
    )}

export default LeaseDocsOnboardingPage;

