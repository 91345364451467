import React, {useState, useEffect, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom'

import {loadVendor, saveVendor, saveVendorCategory, updateItems} from "../../../slices/companySlice";
import store from "../../../app/store";

import {Form, Formik} from "formik";

import FormItem from "../../shared/FormItem";
import insightUtils from "../../../app/insightUtils";
import BasicDropdown from "../../shared/BasicDropdown";
import {useSelector} from "react-redux";
import StateDropdown from "../../shared/StateDropdown";
import CommentsView from "../communications/CommentsView";
import insightRoutes from "../../../app/insightRoutes";

const VendorEditPage = () => {

    let navigate = useNavigate()
    let location = useLocation()
    let params = useParams()

    const { constants, items } = useSelector((state) => state.company)

    const [vendor, setVendor] = useState(null)
    const [baseErrorMessage, setBaseErrorMessage] = useState("")

    useEffect(async() => {
        if (parseInt(params.vendorId) > 0) {
            const results = await store.dispatch(loadVendor({vendorId: params.vendorId})).unwrap()
            let newVendor = Object.assign({}, results.data.vendor)
            newVendor.vendor_category_name = ""
            setVendor(newVendor)
        }
        else {
            setVendor({name: "", email: "", phone_number: "", vendor_category_id: "", vendor_category_name: "", status: "",  street: "", city: "", state: "", zip: ""})
        }
    }, [])

    function closeView(newVendorId) {
        if (location.state && location.state.return_url) {
            let newValues = Object.assign({}, location.state.values)

            // If we added a vendor, send it back to the calling form
            if (newVendorId && location.state.field_to_update) newValues[location.state.field_to_update] = newVendorId

            navigate(location.state.return_url, {state: {values: newValues}})
        }
        else {
            navigate(insightRoutes.vendorList())
        }
    }

    return (
        <>
            <div className="section">
            {vendor && <>
                <h2>{vendor.id ? "Edit " + vendor.name : "Add Vendor"}</h2>
                <p>Use this form to {vendor.id ? "edit" : "create"} a vendor.</p>

                {baseErrorMessage && <div className="text-error">{baseErrorMessage}</div>}

                <Formik
                    initialValues={vendor}
                    onSubmit={async (values, { setSubmitting, setErrors }) => {
                        setBaseErrorMessage("")

                        if (values.vendor_category_id == -1) {
                            if (values.vendor_category_name) {

                                // Push the new vendor category
                                const results = await store.dispatch(saveVendorCategory({vendorCategory: {name: values.vendor_category_name}})).unwrap()
                                let newVendorCategories = Array.from(items.filter((item) => (item.type == 'VendorCategory')))
                                newVendorCategories.push(results.data.vendor_category)
                                store.dispatch(updateItems(newVendorCategories))

                                delete values.vendor_category_name
                                values.vendor_category_id = results.data.vendor_category.id
                            }
                            else {
                                setErrors({vendor_category_name: "cannot be blank"})
                                setSubmitting(false)
                                return
                            }
                        }

                        const results = await store.dispatch(saveVendor({vendor: values})).unwrap()
                        const response = results.data

                        console.log(response)
                        setSubmitting(false);

                        if (response.success) {
                            closeView(response.vendor.id)
                        }
                        else if (response.errors) {
                            setErrors(response.errors)

                            if (response.errors.base) {
                                setBaseErrorMessage(response.errors.base.join(", "))
                            }

                            insightUtils.scrollTo('errors')
                        }

                    }}
                >
                    {({ isSubmitting, values }) => (
                        <Form>
                            <div className="add-property-wrap">
                                <div className="form-row">
                                    <FormItem label="Name" name="name" />
                                </div>

                                <div className="form-row">
                                    <FormItem label="Email" name="email" type="email" optional={true} />
                                    <FormItem label="Phone" name="phone_number" optional={true} mask={insightUtils.phoneNumberMask()} />
                                </div>

                                <div className="form-row">
                                    <FormItem label="Address" name="street" optional={true} />
                                    <FormItem label="City" name="city" optional={true} />
                                    <FormItem label="State" name={`state`} optional={true}>
                                        <StateDropdown name={`state`}/>
                                    </FormItem>
                                    <FormItem label="Zip" name="zip" mask={insightUtils.zipMask()} optional={true} />
                                </div>

                                <div className="form-row">
                                    <FormItem label="Category" name="vendor_category_id" optional={true}>
                                        <BasicDropdown name="vendor_category_id" blankText="-- Select Category --" options={items.filter((item) => (item.type == 'VendorCategory')).concat([{id:-1,name: "Add New Category..."}])} />
                                    </FormItem>
                                    {values.vendor_category_id == -1 && <FormItem label="Enter New Category" name="vendor_category_name" />}
                                    <FormItem label="Status" name="status" optional={true}>
                                        <BasicDropdown name="status" blankText="-- Select Status --" options={constants.vendor_statuses} />
                                    </FormItem>
                                </div>

                                <div className="form-nav">
                                    <a onClick={() => closeView()} className="btn btn-gray" disabled={isSubmitting}>
                                        <span>Cancel</span>
                                    </a>
                                    <button className="btn btn-red" type="submit" disabled={isSubmitting}>
                                        <span>{!isSubmitting ? "Save" : "Saving..."}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </>}

            {vendor && vendor.id &&
                <CommentsView title="Notes" type="CommunicationNotePrivate" subType="notes" relatedObjectType="Vendor" relatedObjectHashId={vendor.id} extraClassName="skinny-column" containerClassName="main-container" />
            }
            </div>
        </>
    )}

export default VendorEditPage;

