import React from 'react';
import {useFormikContext} from "formik";
import {Autocomplete, TextField} from "@mui/material";

const AutocompleteDropdown = ({name, label, blankText, options, handleChange}) => {

    const formikProps = useFormikContext()

    return (
        <Autocomplete
            autoHighlight={true}

            onChange={(event, value) => {
                formikProps.setFieldValue(name, value.id)
                if (handleChange) handleChange(value.id)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    onChange={handleChange}
                    margin="normal"
                    label={label}
                    fullWidth
                    value={formikProps.values[name]}
                />
            )}
            defaultValue={options.find((option) => option.id == formikProps.values[name])}
            options={blankText ? [{id: "", name: blankText}].concat(options) : options}
            getOptionLabel={(option) => (option.name)}
            isOptionEqualToValue={(option, value) => option.id === value.id}

        />
    )}

export default AutocompleteDropdown;

