import React from 'react';
import {searchForPropertyOwners} from "../../../slices/propertySlice";
import store from "../../../app/store";

import {Link} from "react-router-dom";
import PropertyOwnerListRow from "./PropertyOwnerListRow";

import ListPage from "../../shared/ListPage";
import insightRoutes from "../../../app/insightRoutes";
import {useSelector} from "react-redux";

const PropertyOwnerListPage = ({}) => {

    const { currentUser } = useSelector((state) => state.user)

    async function runSearch(text, page) {
        const results = await store.dispatch(searchForPropertyOwners({searchText: text, page: page})).unwrap()
        return {total: results.data.total, objects: results.data.property_owners}
    }

    function generateTableRow(propertyOwner, key) {
        return (<PropertyOwnerListRow key={key} propertyOwner={propertyOwner} />)
    }

    return (
        <>
            {currentUser.property_owners_view && <ListPage
                title="Property Owners"
                runSearch={runSearch}
                addButton={currentUser.property_owners_edit ? <Link to={insightRoutes.propertyOwnerNew()} className="btn btn-red"><span>Add Property Owner <i className="fas fa-plus"></i></span></Link> : null}
                columns={[
                    {label: "Property Owner", class: "st-col-30", sort_by: "name"},
                    {label: "Type", class: "st-col-30", sort_by: "owner_type"}
                ]}
                generateTableRow={generateTableRow}
            />}
        </>

    )}

export default PropertyOwnerListPage;

