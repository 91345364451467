import React from 'react';
import {Outlet} from "react-router-dom";

const BasePage = ({}) => {

    return (
        <>
            <Outlet />
        </>

    )}

export default BasePage;

