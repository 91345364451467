import React from 'react';

const ScreeningTermsView = ({}) => {

    return (
        <>
            <iframe src="/pdfs/company-screening-terms.pdf" width="100%" height="100%">
            </iframe>
        </>
    )}

export default ScreeningTermsView;

