import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit'
import {client} from "../app/client";

const initialState = {

}

export const loadLease = createAsyncThunk('results/loadLease', async ({leaseId}) => {
    console.log("START loadLease ", leaseId)

    const response = await client.get( "/api/internal/leases/" + leaseId)
    console.log("END loadLease")
    return {data: response}

})

export const loadCurrentLeases = createAsyncThunk('results/loadCurrentLeases', async ({propertyId}) => {
    console.log("START loadCurrentLeases ", propertyId)

    const response = await client.post( "/api/internal/leases/current", {property_id: propertyId})
    console.log("END loadCurrentLeases")
    return {data: response}

})

export const determineCheckPrintingEligibility = createAsyncThunk('results/determineCheckPrintingEligibility', async ({leaseId}) => {
    console.log("START determineCheckPrintingEligibility ", leaseId)

    const response = await client.get( "/api/internal/leases/" + leaseId + "/determine_check_printing_eligibility")
    console.log("END determineCheckPrintingEligibility")
    return {data: response}

})

export const createLease = createAsyncThunk('results/createLease', async ({propertyId, unitId}) => {
    console.log("START createLease ", propertyId, unitId)

    const response = await client.post( "/api/internal/leases", {property_id: propertyId, unit_id: unitId})
    console.log("END createLease")
    return {data: response}

})

export const createExistingLease = createAsyncThunk('results/createExistingLease', async (lease) => {
    console.log("START createExistingLease ", d)

    const response = await client.post( "/api/internal/leases/create_existing", {lease: lease})
    console.log("END createExistingLease")
    return {data: response}

})


export const saveLease = createAsyncThunk('results/saveLease', async ({lease}) => {
    console.log("START saveLease ", lease)

    let url = "/api/internal/leases"
    let method = "POST"

    if (lease.hash_id) {
        url += "/" + lease.hash_id
        method = "PUT"
    }

    const response = await client.call(method, url, {lease: lease})
    console.log("END saveLease")
    return {data: response}

})

export const searchForLeases = createAsyncThunk('results/searchForLeases', async ({propertyId, searchText, mode, status, daysFrom, daysTo}) => {
    console.log("START searchForLeases")

    const response = await client.post('/api/internal/leases/search', {property_id: propertyId, search_text: searchText, mode: mode, status: status, days_from: daysFrom, days_to: daysTo})
    console.log("END searchForLeases")
    return {data: response}

})

export const cancelMoveIn = createAsyncThunk('results/cancelMoveIn', async ({leaseId, paymentsToRefund}) => {
    console.log("START cancelMoveIn")

    const response = await client.post('/api/internal/leases/'+leaseId+'/cancel_move_in', {payments_to_refund: paymentsToRefund})
    console.log("END cancelMoveIn")
    return {data: response}

})

export const getLeaseDocuments = createAsyncThunk('results/getLeaseDocuments', async ({leaseId}) => {
    console.log("START getLeaseDocuments")

    const response = await client.get("/api/internal/leases/" + leaseId + "/lease_documents")
    console.log("END getLeaseDocuments")
    return {data: response}

})

export const getMoveOutDocuments = createAsyncThunk('results/getMoveOutDocuments', async ({leaseId}) => {
    console.log("START getMoveOutDocuments")

    const response = await client.get("/api/internal/leases/" + leaseId + "/move_out_documents")
    console.log("END getMoveOutDocuments")
    return {data: response}

})

const leaseSlice = createSlice({
    name: 'lease',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {

    }
})

export const {} = leaseSlice.actions

export default leaseSlice.reducer
