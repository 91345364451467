import React from 'react';
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";


const TopBar = ({}) => {

    const { currentUser } = useSelector((state) => state.user)

    return (
        <div className="topnav">
            <div className="topnav-block">
                <div className="logo-block">
                    <Link to="/" className="logo"><img src="/images/logo-ri.svg" alt="Renter Insight" /></Link>
                </div>
                {currentUser && <div>Welcome, {currentUser.first_name}</div>}
            </div>
        </div>

    )}

export default TopBar;

