import React from 'react';
import insightUtils from "../../../../app/insightUtils";

const LeaseDocumentsBlock = ({lease}) => {

    return (
        <div className="flex-grid-item">
            <h3>Lease Documents</h3>

            <p>Coming Soon</p>
        </div>
    )}

export default LeaseDocumentsBlock;

