import React, {useState} from 'react';
import {useSelector} from "react-redux";
import insightUtils from "../../../../app/insightUtils";

const LeaseDocsOnboardingLandingView = ({}) => {

    const { settings } = useSelector((state) => state.company)
    const globalSettings = insightUtils.getSettings(settings)

    const [showingFees, setShowingFees] = useState(false)

    return (
        <>
            {globalSettings && <div className="section">

                <div className="circle-bg"></div>

                <i className="far fa-file-signature section-icon section-icon-red"></i>

                <div className="title-block">
                    <h1>Get Started Now</h1>

                </div>

                <div className="onboarding onboarding-wrap">

                    <div className="column column-img-mobile">
                        <img className="img-responsive img-rounded-corners" src="/images/marketing-products-lease-header.jpg"/>
                    </div>

                    <div className="column">
                        <h2>State-Specific Lease Documents</h2>
                        <p>Stop using outdated leases and ensure you’re in compliance. Our lease agreements by Rocket Lawyer are customized for each state to include all required forms and disclosures. We help you create, customize, electronically sign and store your leases and other related forms.</p>

                        <div className="btn btn-red btn-large" onClick={() => alert('Oops... not yet.')}><span>Get Started</span></div>

                        <div className="column-split-wrap">
                            <div className="column-split">
                                <i className="icon-red icon-vert far fa-file-contract"></i>
                                <h3>Lease Agreements</h3>
                                <ul>
                                    <li>State Specific Lease Agreements</li>
                                    <li>eSignature: All parties can sign electronically</li>
                                    <li>Quickly Generate Agreements: Forms pre-populated with property and resident application information</li>
                                    <li className="btn-fees btn-onboard-pricing-lease-docs"><a onClick={() => setShowingFees(true)}>View Pricing<sup>*</sup></a></li>
                                </ul>

                            </div>

                            <div className="column-split">
                                <i className="icon-red icon-vert far fa-files"></i>
                                <h3>Legal Forms</h3>
                                <ul>
                                    <li>Access to hundreds of Real Estate Forms including:</li>
                                    <ul>
                                        <li>Lease Agreement</li>
                                        <li>Eviction Notices</li>
                                        <li>Co-Signer Agreement</li>
                                        <li>Contractor Agreements</li>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="column column-img-desktop">
                        <img className="img-responsive img-rounded-corners" src="/images/marketing-products-lease.jpg"/>
                    </div>

                </div>


            </div>}

            {showingFees && <div className="overlay-container">

                <div className="overlay-box overlay-box-large">
                    <div className="btn-close-overlay" onClick={() => setShowingFees(false)}>
                        <i className="fal fa-times-circle"></i>
                    </div>

                    <div className="overlay-box-content">
                        <h2>Lease Document Pricing</h2>

                        <div className="flex-table flex-table-4col">
                            <div className="flex-row">
                                <div className="flex-cell leftcorner-cell"></div>
                                <div className="flex-cell title-cell cell-top cell-left">Standard Plan</div>
                                <div className="flex-cell title-cell cell-top cell-right">Professional Plan</div>
                            </div>

                            <div className="flex-row row-gray">
                                <div className="flex-cell title-cell cell-left cell-top">Lease Agreement</div>
                                <div className="flex-cell">$9.95</div>
                                <div className="flex-cell cell-right">Included</div>
                            </div>


                            <div className="flex-row">
                                <div className="flex-cell title-cell cell-left">a-la carte forms</div>
                                <div className="flex-cell">$4.95</div>
                                <div className="flex-cell cell-right">Included</div>
                            </div>

                            <div className="flex-row row-gray">
                                <div className="flex-cell title-cell cell-left cell-bottom">eSignature</div>
                                <div className="flex-cell cell-bottom">$4.95</div>
                                <div className="flex-cell cell-right cell-bottom">Included</div>
                            </div>

                        </div>


                        <div className="form-nav">
                            <div className="btn btn-red" onClick={() => setShowingFees(false)}><span>Close</span></div>
                        </div>
                    </div>
                </div>
            </div>}
        </>

    )}

export default LeaseDocsOnboardingLandingView;

